const reservedFields = [
  'address1',
  'address2',
  'city',
  'country',
  'latitude',
  'longitude',
  'region',
  'zip',
  'email',
  'title',
  'phone_number',
  'organization',
  'first_name',
  'last_name',
  'timezone',
];

const locationFields = ['address1', 'address2', 'city', 'country', 'region', 'zip', 'timezone'];

module.exports = { reservedFields, locationFields };
